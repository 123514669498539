<template>
  <v-card :loading="ui.loading" :disabled="ui.disabled" class="rounded-lg overflow-hidden">
    <v-card-text class="px-0 pb-0">
      <v-row class="d-flex"
        style="border-radius: 0">
        <v-col cols="5" class="d-flex">
          <v-row>
            <v-col cols="12" class="blue py-6 px-12 d-flex">
              <v-toolbar-title class="d-flex flex-column text-center title white--text pl-0 pb-10" style="margin: auto">
                <v-icon class="my-10" size="125">mdi-tune-vertical</v-icon>
                {{ item.name }}
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="grey lighten-3 elevation-4 py-8 px-8">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-icon @click="close">mdi-close </v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col>
              <div class="button text-uppercase pb-4">
                {{ $vuetify.lang.t('$vuetify.administration.setting.edit.name')}}
              </div>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-0 mt-3 mb-3"
              >
                {{ ui.alert.message }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.name"
                :counter="45"
                :rules="form.rules.name"
                :label="$vuetify.lang.t('$vuetify.administration.setting.edit.ui.form.fields.name.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.value"
                :counter="200"
                :rules="form.rules.value"
                :label="$vuetify.lang.t('$vuetify.administration.setting.edit.ui.form.fields.value.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.data.description"
                :label="$vuetify.lang.t('$vuetify.administration.setting.edit.ui.form.fields.description.label')"
              ></v-textarea>
            </v-col>
            <v-card-actions class="d-xs-block px-3 px-sm-7">
              <v-btn
                text
                v-on:click="reset()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                text
                v-on:click="submit()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
              </v-btn>
            </v-card-actions>
          </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    onConfirm: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    form: {
      valid: false,
      default_data: {},
      data: {
        name: '',
        value: '',
        description: ''
      },
      rules: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    async fetchSetting (id) {
      try {
        this.ui.loading = true
        this.ui.disabled = true
        this.form.default_data = (await this.axios.get('/administration/setting/one/' + id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.reset()
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 404:
              await this.$router.push({
                name: 'AdminSettingList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.not_find')
                  }
                }
              })
              break

            default:
              await this.$router.push({
                name: 'AdminSettingList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert-octagon',
                    type: 'error',
                    message: this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.unknown_fetch')
                  }
                }
              })
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    async submit () {
      if (!this.validate()) {
        return false
      }
      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/administration/setting/edit/' + this.form.default_data.id, {
          name: this.form.data.name,
          value: this.form.data.value,
          description: this.form.data.description
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        await this.fetchSetting(this.form.default_data.id)
        this.onConfirm()
        this.reset()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.fail'))
              break

            case 404:
              await this.$router.push({
                name: 'AdminSettingList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.not_find')
                  }
                }
              })
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.administration.setting.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.ui.alert.enable = false
      setTimeout(() => {
        this.form.data.name = this.form.default_data.name
        this.form.data.value = this.form.default_data.value
        this.form.data.description = this.form.default_data.description
      }, 10)
    },
    close () {
      this.reset()
      this.onCancel()
    }
  },
  mounted () {
    this.fetchSetting(this.item.id)
  },
  created () {
    this.form.rules = {
      name: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 200) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      value: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ]
    }
  }
}
</script>
