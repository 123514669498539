<template>
  <v-card :loading="ui.loading" :disabled="ui.disabled" class="rounded-lg overflow-hidden">
    <v-row
      style="border-radius: 0"
      class="blue py-6 px-5 px-sm-9">
      <v-col cols="10" class="pb-0">
        <v-toolbar-title class="title white--text pl-0">
          {{ $vuetify.lang.t('$vuetify.administration.setting.add.name') }}
        </v-toolbar-title>
      </v-col>
      <v-col cols="2" class="d-flex justify-end">
        <v-icon @click="close">mdi-close </v-icon>
      </v-col>
    </v-row>
    <v-card-text class="px-0 pb-0">
      <v-row class="d-flex"
        style="border-radius: 0">
        <v-col cols="12" class="grey lighten-3 elevation-4 py-4 px-8">
          <v-form
            ref="form"
            v-model="form.valid"
            lazy-validation>
          <v-row class="py-2 px-5 px-sm-9">
            <v-col cols="12">
              <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-0 mb-3"
              >
                {{ ui.alert.message }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.name"
                :counter="45"
                :rules="form.rules.name"
                :label="$vuetify.lang.t('$vuetify.administration.setting.add.ui.form.fields.name.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="form.data.value"
                :counter="200"
                :rules="form.rules.value"
                :label="$vuetify.lang.t('$vuetify.administration.setting.add.ui.form.fields.value.label')+'*'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="form.data.description"
                :label="$vuetify.lang.t('$vuetify.administration.setting.add.ui.form.fields.description.label')"
              ></v-textarea>
            </v-col>
            <v-card-actions class="d-xs-block pr-3 pr-sm-7 pb-10">
              <v-btn class="text-left"
                text
                v-on:click="reset()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                text
                v-on:click="submit()">
                {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
              </v-btn>
            </v-card-actions>
          </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    onConfirm: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    form: {
      valid: false,
      data: {
        code: '',
        value: ''
      },
      rules: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    async submit () {
      if (!this.validate()) {
        return false
      }
      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/administration/setting/add/', {
          name: this.form.data.name,
          value: this.form.data.value,
          description: this.form.data.description
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        this.reset()
        this.onConfirm()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.administration.setting.add.notification.added'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.administration.setting.add.notification.fail'))
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.administration.setting.add.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.ui.alert.enable = false
    },
    close () {
      this.reset()
      this.onCancel()
    }
  },
  created () {
    this.form.rules = {
      name: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 200) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      value: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ]
    }
  }
}
</script>
